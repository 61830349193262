import React from 'react'
import Script from 'next/script'
import { logger } from 'utils/logger'

export const HeapScript = () => (
  <>
    {process.env.HEAP_APP_ID && (
      <Script
        id="heap-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
heap.load('${process.env.HEAP_APP_ID}');
`,
        }}
        onError={(e) => {
          logger('error', new Error('Heap failed to load', { cause: e }))
        }}
      />
    )}
  </>
)

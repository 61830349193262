import type { AnalyticsInstance } from 'analytics'

export type HeapConfig = {
  campaignId: string
  orgId: string
  isCartEnabled: boolean
}

export type HeapCookie = {
  userId: string
  pageviewId: string
  sessionId: string
}

type HeapPayload = {
  event?: string // name of tracked event
  properties: object // data to track
  meta: unknown
  type: 'page' | 'track' // | "initialize"??
  userId: string
  anonymousId: string
  options: unknown
}

type HeapTrackingData = {
  config: HeapConfig
  payload: HeapPayload
  instance: AnalyticsInstance
}

// https://classy.atlassian.net/wiki/spaces/PT/pages/2310013043/Campaign+Studio+-+Heap+Implementation
const getSuperProperties = ({ campaignId, orgId, isCartEnabled }: HeapConfig) => {
  return {
    environment: process.env.APP_ENV,
    orgId,
    campaignId,
    campaignType: 'studio',
    hasCartEnabled: isCartEnabled,
  }
}

/**
 * Custom heap analytics plugin:
 * https://getanalytics.io/plugins/writing-plugins/
 * https://developers.heap.io/docs/web
 */
export const heap = ({ campaignId, orgId, isCartEnabled }: HeapConfig) => ({
  name: 'heap',
  config: {
    campaignId,
    orgId,
    isCartEnabled,
  },
  page: ({ payload, config }: HeapTrackingData) => {
    const properties = { payload }
    const superProperties = getSuperProperties(config)
    window.heap.track('Page View', { ...properties, ...superProperties })
  },
  track: ({ payload, config }: HeapTrackingData) => {
    const { event, properties } = payload

    const superProperties = getSuperProperties(config)

    window.heap.track(event as string, { ...properties, ...superProperties })
  },
  loaded: () => {
    return !!window.heap
  },
})

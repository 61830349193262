import React from 'react'

import { initializeAnalytics } from './analytics'
import type { AnalyticsServiceSettingsDto } from 'models/studio'
import { initializeClassyTag } from './classyTag'

interface useLoadAnalyticsParams {
  analyticsSettings: AnalyticsServiceSettingsDto[]
  campaignId: string
  orgId: string
  appEnv: string
  isCartEnabled: boolean
}

export const useLoadAnalytics = ({
  analyticsSettings,
  campaignId,
  orgId,
  appEnv,
  isCartEnabled,
}: useLoadAnalyticsParams) => {
  const [isLoaded, setIsLoaded] = React.useState(false)

  React.useEffect(() => {
    if (window.airgap && analyticsSettings.length) {
      // * analyticsSettings is resolved for both analytics and channel settings
      initializeAnalytics(analyticsSettings, { campaignId, orgId, isCartEnabled })

      initializeClassyTag({
        appEnv,
        campaignId,
        hasCartEnabled: isCartEnabled,
        orgId,
        settings: analyticsSettings,
      })

      setIsLoaded(true)
    }
  }, [appEnv, analyticsSettings, campaignId, orgId, isCartEnabled])

  return isLoaded
}

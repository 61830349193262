import { css, Global } from '@emotion/react'
import { AppProps } from 'next/app'
import {
  normalizeCss,
  pageResetCss,
  fullPageHeightCss,
  pageContainerCss,
  DEFAULT_THEME,
  CLASSY_DESIGN_TOKENS,
} from '@classy/campaign-page-blocks'

import { ErrorBoundary } from 'components/ErrorBoundary'
import { start } from 'utils/bugsnag'
import { CLASSY_GOOGLE_FONTS } from 'utils/googleFonts'
import {
  useLoadAnalytics,
  HeapScript,
  FacebookPixelScript,
  resolveAnalyticsSettings,
} from 'services/analytics'
import { ConsentProvider } from 'services/transcend'

// Initialize Bugsnag
start()

const globalResetCss = css`
  :root {
    /* Base tokens */
    ${CLASSY_DESIGN_TOKENS}
    ${DEFAULT_THEME}

    /* Google Fonts - Override base values with next/font optimized font-families*/
    ${CLASSY_GOOGLE_FONTS}
  }

  ${normalizeCss}
  ${pageResetCss}
  ${fullPageHeightCss}

  body {
    font-family: var(--classy-font__font-family--base);
    ${pageContainerCss}
  }

  /**
   * In some cases, a tracking service adds a non-hidden image to the end of the <body>, which
   * causes double scroll bars. The double scroll bars occurs because html, body, and div#__next
   * have a height of 100% (see fullPageHeightCss). When an image is added after div#__next, the
   * body is forced to be larger than height 100%, triggering an extra scroll bar.
   *
   * For now, only targeting direct children of body.
   */
  body > img {
    display: block;
    height: 0;
  }
`
const airgapSrcUrl = `https://transcend-cdn.com/cm${process.env.APP_ENV !== 'prod' ? '-test' : ''}/d1a4d701-2ca2-487c-9b90-59558395eb44/airgap.js`

const App = ({ Component, pageProps }: AppProps) => {
  // FYI, pageProps is initially empty, hence why ?. is used for all properties
  const { pageConfig } = pageProps

  const campaignId = pageConfig?.campaignId
  const orgId = pageConfig?.orgId

  /**
   * resolveAnalyticsSettings filters and/or transforms analyticsServiceSettings
   * and channelsSettings data, and returns an array of objects combining
   * analyticsServiceSettings and channelsSettings.
   *
   * If analyticsServiceSettings and channelsSettings are both false, resolveAnalyticsSettings
   * will return an empty array.
   */
  const analyticsSettings = resolveAnalyticsSettings(
    pageConfig?.analyticsServiceSettings ?? [],
    pageConfig?.channelsSettings ?? [],
    orgId,
  )

  const loadFacebookPixelScript = analyticsSettings.some(
    (setting) => setting.service_name === 'facebook_pixel',
  )
  const isAnalyticsLoaded = useLoadAnalytics({
    analyticsSettings: analyticsSettings,
    appEnv: process.env.APP_ENV,
    campaignId,
    orgId,
    isCartEnabled: pageConfig?.isCartEnabled || false,
  })

  return (
    <ConsentProvider airgapSrc={airgapSrcUrl}>
      <Global styles={globalResetCss} />
      <ErrorBoundary>
        <Component {...{ ...pageProps, isAnalyticsLoaded }} />
      </ErrorBoundary>
      <HeapScript />
      {loadFacebookPixelScript && <FacebookPixelScript />}
    </ConsentProvider>
  )
}

export default App

import * as React from 'react'
import type { NextPage } from 'next'
import Link from 'next/link'
import Head from 'next/head'
import styled from '@emotion/styled'
import { ClassyFooter, PageHeader, OpenInNew, BREAKPOINTS } from '@classy/campaign-page-blocks'
import { MemberDropdown } from 'components/MemberDropdown'
import { SsoSdk } from 'components/SsoSdk'
import { useSsoMember } from 'hooks/useSsoMember'
import { onLogin } from 'services/sso'

const StyledContainer = styled.div`
  background-color: var(--theme-color__background-accent);
  color: var(--theme-color__text-general);
  font-family: var(--theme-font__font-family--general);
  text-align: left;
  padding: 40px 24px;
  display: flex;
  flex: 1 0 0;
  justify-content: center;
  align-items: center;

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    padding: 64px;
    text-align: center;
  }
`

const StyledBody = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: fit-content;

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    max-width: 624px;
  }

  @media (min-width: ${BREAKPOINTS.desktop}px) {
    max-width: 752px;
  }
`

const StyledHeader = styled.h1`
  align-self: stretch;
  font-size: var(--theme-font__heading-lg--font-size);
  font-weight: var(--theme-font__heading-lg--font-weight);
  line-height: var(--theme-font__heading-lg--line-height);
`
const StyledParagraph = styled.span`
  font-size: var(--theme-font__paragraph-lg--font-size);
  font-weight: var(--theme-font__paragraph-lg--font-weight);
  line-height: 32px;
`

const StyledButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 56px;
  width: 100%;
  padding: 12px 24px;
  gap: 4px;
  border-radius: 8px;
  background: var(--theme-color__brand-tertiary);
  color: white;
  text-decoration: none;

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    width: fit-content;
  }
`

const Classy404: NextPage = () => {
  const [isSsoSdkLoaded, setIsSsoSdkLoaded] = React.useState(false)
  const member = useSsoMember(isSsoSdkLoaded)

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" sizes="any" />
        <title>Page not found</title>
      </Head>
      <PageHeader hidePlaceholder>
        {member && <MemberDropdown member={member} />}
        <SsoSdk onReady={() => setIsSsoSdkLoaded(true)} />
      </PageHeader>
      <StyledContainer>
        <StyledBody>
          <StyledHeader>Page not found.</StyledHeader>
          <StyledParagraph>
            Sorry, we couldn’t find this page. There may have been a typo or broken link. Please
            check the URL and try again.
          </StyledParagraph>
          <StyledButton href="https://support.classy.org/s/" target="_blank">
            <OpenInNew fill="currentcolor" />
            <span>Help center</span>
          </StyledButton>
        </StyledBody>
      </StyledContainer>
      <footer>
        <ClassyFooter data-id="cp-footer-login" isLoggedIn={!!member} onLogin={onLogin} />
      </footer>
    </>
  )
}

export default Classy404

import { ClassyTag, CommandName } from '@classy/classytag'
import type { AnalyticsServiceSettingsDto } from 'models/studio'

export interface ClassyTagConfig {
  appEnv: string
  campaignId: string
  hasCartEnabled: boolean
  orgId: string
  settings: AnalyticsServiceSettingsDto[]
}

export const initializeClassyTag = ({
  appEnv,
  campaignId,
  hasCartEnabled,
  orgId,
  settings,
}: ClassyTagConfig) => {
  const classyTag = new ClassyTag()

  /**
   * * Tealium
   * * Look for Tealium config
   * * If Tealium config exists setup and init
   */
  const tealium = settings.find((setting) => setting.service_name === 'tealium') as
    | AnalyticsServiceSettingsDto<{ account: string; profile: string }, object>
    | undefined

  if (tealium) {
    classyTag.configure({
      tealium: {
        account: tealium.parameters.account,
        profile: tealium.parameters.profile,
        environment: appEnv,
      },
    })

    classyTag.execute(CommandName.SET_SUPER_PROPERTIES, 'tealium', {
      environment: appEnv,
      campaignId,
      hasCartEnabled,
      orgId,
    })

    classyTag.execute(CommandName.INIT, 'tealium')
  }
}
